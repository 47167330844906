import {
  Link,
} from '@chakra-ui/react';
import { HydratedDeliveryStop } from '@tradeaze-packages/schemas';
import React from 'react';
import { buildTrackingLink } from '@tradeaze/shared/utils';
import { ENV_NAME } from '@tradeaze/frontend/utils';

export const TrackingLink: React.FunctionComponent<{
  deliveryStop: HydratedDeliveryStop
  isAdmin?: boolean
}> = ({
  deliveryStop,
  isAdmin,
}) => {
  const { delivery } = deliveryStop || {};
  return isAdmin && ENV_NAME ? (
          <Link
            color={'blackAlpha.700'}
            fontSize={'sm'}
            href={buildTrackingLink(
              delivery?.deliveryId || '',
              ENV_NAME,
            )}
          >
            {buildTrackingLink(
              delivery?.deliveryId || '',
              ENV_NAME,
            )}
          </Link>
        ) : null
};
