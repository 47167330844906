import { Box, Button, Flex, Heading, Stack } from '@chakra-ui/react';
import { HydratedDeliveryStop, OrderType } from '@tradeaze-packages/schemas';
import React from 'react';
import { DeliveryPostcodes } from '../../delivery';
import { useNavigate } from 'react-router-dom';
import { ChevronLeftIcon } from '@chakra-ui/icons';

const orderHeading: Record<OrderType, string> = {
  MULTI_COLLECTION: 'Multi-Collection',
  A_TO_B: 'A To B',
  MULTI_DROP: 'Multi-Drop',
};

export const OrderSummaryHeading: React.FC<{
  type: OrderType;
  pickups?: HydratedDeliveryStop[];
  dropoffs?: HydratedDeliveryStop[];
  merchantOrderReference?: string | null;
}> = ({ type, pickups, dropoffs, merchantOrderReference }) => {
  const navigate = useNavigate();
  return (
    <Stack
      justify={'space-between'}
      direction={['column', 'row']}
      spacing={[3, 10]}
      mb={8}
      alignItems={['start', 'center']}
    >
      <Box>
        <Flex align="center" mb={4}>
          <Button
            mr="5px"
            variant={'ghost'}
            colorScheme={'gray'}
            onClick={() => navigate('/')}
            px={0}
          >
            <ChevronLeftIcon fontSize={32} />
          </Button>
          <Heading size="md" fontSize="18px" >
            {orderHeading[type]} Order
          </Heading>
        </Flex>
        <Flex ml="20px" mt="10px">
          <DeliveryPostcodes pickup={pickups} dropOff={dropoffs} />
        </Flex>
      </Box>
      {merchantOrderReference && (
        <Heading size={'md'} fontSize="16px" fontWeight="400" color="#666668">
          {merchantOrderReference}
        </Heading>
      )}
    </Stack>
  );
};
