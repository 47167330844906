import { z } from 'zod';
import { validatePostcode } from './../utils/validatePostcode';
import { PositionSchema } from './shared-schema';
import { requireUkPhoneNumber } from '../utils/requireUkPhoneNumber';
import { FeatureFlagsSchema } from './feature-flags-schema';

const OpeningTimesSchema = z.object({
  monday: z
    .object({
      open: z.string(),
      close: z.string(),
    })
    .nullable(),
  tuesday: z
    .object({
      open: z.string(),
      close: z.string(),
    })
    .nullable(),
  wednesday: z
    .object({
      open: z.string(),
      close: z.string(),
    })
    .nullable(),
  thursday: z
    .object({
      open: z.string(),
      close: z.string(),
    })
    .nullable(),
  friday: z
    .object({
      open: z.string(),
      close: z.string(),
    })
    .nullable(),
  saturday: z
    .object({
      open: z.string(),
      close: z.string(),
    })
    .nullable(),
  sunday: z
    .object({
      open: z.string(),
      close: z.string(),
    })
    .nullable(),
});

export const MerchantCategoryIdSchema = z.enum([
  'ELECTRICAL',
  'BUILDING',
  'PAINTING_DECORATING',
  'PLUMBING_HEATING',
]);

export const CategoriesSchema = z.object({
  categories: MerchantCategoryIdSchema.array(),
});

export const MerchantCategorySchema = z.object({
  categoryId: MerchantCategoryIdSchema,
  name: z.string(),
});

export const ContactEmailSchema = z.object({
  contactEmailId: z.string(),
  createdAt: z.string().nullish(),
  updatedAt: z.string().nullish(),
  email: z.string().email(),
  isPrimary: z.boolean(),
  enableNotifications: z.boolean(),
  merchantId: z.string(),
});

export const CreateContactEmailSchema = ContactEmailSchema.partial({
  contactEmailId: true,
  createdAt: true,
  updatedAt: true,
  merchantId: true,
});

export const MerchantProfileSchema = z.object({
  merchantId: z.string(),
  merchantName: z.string(),
  username: z.string().nullish(),
  address: z.string(),
  city: z.string(),
  postCode: z.string().refine((postcode) => validatePostcode(postcode), {
    message: 'Invalid UK postcode',
  }),
  contactEmails: z.array(ContactEmailSchema),
  contactNumber: requireUkPhoneNumber,
  referencePrefix: z.string().nullish(),
  position: PositionSchema.nullish(),
  openingTimes: OpeningTimesSchema.nullish(),
  categories: z.array(MerchantCategorySchema).nullish(),
  stripeAccountId: z.string().nullish(),
  regionId: z.string().nullish(),
});

export const CreateMerchantProfileSchema = MerchantProfileSchema.omit({
  stripeAccountId: true,
  regionId: true,
}).extend({
  contactEmails: z.array(CreateContactEmailSchema).min(1),
});

export const MerchantAccountSchema = MerchantProfileSchema.extend({
  displayInApp: z.boolean().nullish(),
  featureFlags: FeatureFlagsSchema.nullish(),
  invoicingName: z.string().nullish(),
});

export const UpdateMerchantAccountSchema = MerchantAccountSchema.extend({
  contactEmails: z.array(CreateContactEmailSchema),
})
  .partial() // allow partial updates
  .strict(); // throw if any extra fields are passed

export const GetMerchantProfilesFiltersSchema = z.object({
  categoryId: MerchantCategoryIdSchema.optional(),
  merchantName: z.string().optional(),
  displayInApp: z
    .preprocess((arg) => arg === 'true' || arg === true, z.boolean())
    .optional(),
});

export type MerchantProfile = z.infer<typeof MerchantProfileSchema>;

export type MerchantAccount = z.infer<typeof MerchantAccountSchema>;

export type CreateMerchantProfile = z.infer<typeof CreateMerchantProfileSchema>;

export type UpdateMerchantAccount = z.infer<typeof UpdateMerchantAccountSchema>;

export type MerchantCategory = z.infer<typeof MerchantCategorySchema>;

export type MerchantCategoryID = z.infer<typeof MerchantCategoryIdSchema>;

export type OpeningTimes = z.infer<typeof OpeningTimesSchema>;

export type GetMerchantProfilesFilters = z.infer<
  typeof GetMerchantProfilesFiltersSchema
>;

export type MerchantCategoryId = z.infer<typeof MerchantCategoryIdSchema>;
