import { z } from 'zod';
import {
  CreateOrderSchema,
  OrderSchema,
  CreateOrderAToBSchema,
  CreateOrderMultiCollectionSchema,
  CreateOrderMultiDropSchema,
} from '../../../models';

export const CreateOrderBodySchema = CreateOrderSchema;

export const CreateOrderAToBBodySchema = CreateOrderAToBSchema;

export const CreateOrderMultiCollectionBodySchema =
  CreateOrderMultiCollectionSchema;

export const CreateOrderMultiDropBodySchema = CreateOrderMultiDropSchema;

export const CreateOrderResponseSchema = OrderSchema;

export type CreateOrderBody = z.infer<typeof CreateOrderBodySchema>;

export type CreateOrderResponse = z.infer<typeof CreateOrderResponseSchema>;
