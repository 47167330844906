import { validatePostcode, getGeocodeResults } from '@tradeaze/shared/utils';
import { MAPBOX_PUBLIC_ACCESS_KEY } from '@tradeaze/frontend/utils';
import { UseFormReturn } from 'react-hook-form';
import { CreateDeliveryStop } from '@tradeaze-packages/schemas';

export const updatePositionFromPostcode = async (
  postcode: string,
  setValue: UseFormReturn<CreateDeliveryStop>['setValue'],
) => {
  if (!validatePostcode(postcode)) {
    setValue('position', undefined);
    return;
  }

  try {
    const results = await getGeocodeResults(postcode, {
      mapboxAccessKey: MAPBOX_PUBLIC_ACCESS_KEY,
    });

    const postcodeMatch = results.features.find(
      (feature: { id: string; text: string; center: [number, number] }) =>
        feature.text.toLowerCase().replace(/\s/g, '') ===
        postcode.toLowerCase().replace(/\s/g, ''),
    );

    if (!postcodeMatch) {
      setValue('position', undefined);
      return;
    }

    const position = {
      latitude: postcodeMatch.center[1],
      longitude: postcodeMatch.center[0],
    };

    setValue('position', position);
  } catch (error) {
    console.error('Error updating position from postcode:', error);
    setValue('position', undefined);
  }
};
