import { HStack, Icon, Text } from '@chakra-ui/react';
import { HydratedDeliveryStop } from '@tradeaze-packages/schemas';
import { FaLongArrowAltRight } from 'react-icons/fa';
import { BsDot } from "react-icons/bs";

export const DeliveryPostcodes: React.FC<{
  pickup?: HydratedDeliveryStop[];
  dropOff?: HydratedDeliveryStop[];
}> = ({ pickup, dropOff }) => {
  return (
    <HStack flexWrap="wrap">
      {pickup?.map(({postCode}, index: number) => (
        <>
          {index > 0 ? <BsDot /> : null}
          <Text fontWeight="500" noOfLines={1}>{postCode?.toLocaleUpperCase()}</Text>
        </>
      ))}
      {dropOff && <Icon as={FaLongArrowAltRight} color="blackAlpha.500" />}
      {dropOff?.map(({postCode}, index: number) => (
        <>
          {index > 1 ? <BsDot /> : null}
          <Text fontWeight="500" noOfLines={1}>{postCode?.toLocaleUpperCase()}</Text>
        </>
      ))}
    </HStack>
  );
};
