import { Box, Text } from '@chakra-ui/react';
import React from 'react';
import { FormAttribute } from '../../../form';
import { UseFormReturn } from 'react-hook-form';
import { CreateDeliveryStop } from '@tradeaze-packages/schemas';

type ManualAddressSectionProps = {
  handleManualAddress: () => void;
  showFullAddress: boolean;
  isManualAddress: boolean;
  form: UseFormReturn<CreateDeliveryStop>;
  isDropOff?: boolean;
  cypressTestId: string;
};

export const ManualAddressSection = ({
  handleManualAddress,
  isManualAddress,
  showFullAddress,
  form,
  isDropOff,
  cypressTestId,
}: ManualAddressSectionProps) => {
  const {
    register,
    formState: { errors },
  } = form;

  return (
    <>
      <Box display={'flex'} textAlign={'center'} mt={2}>
        <Text fontSize={12} my={2} color={'blackAlpha.500'}>
          Can't find the address?{'\u00A0'}
        </Text>
        <Text
          fontSize={12}
          cursor={'pointer'}
          my={2}
          onClick={handleManualAddress}
          color={'blue.600'}
          data-cy={`${cypressTestId}-enter-manually`}
        >
          Enter manually
        </Text>
      </Box>
      {showFullAddress ? (
        <Box my={6}>
          {!isDropOff ? (
            <FormAttribute
              id={'companyName'}
              label="Company Name"
              isRequired
              isInvalid={false}
              placeholder={'Company Name'}
              error={errors.companyName}
              mb={4}
              data-cy={`${cypressTestId}-companyName`}
              inputCypressId={`${cypressTestId}-companyName-input`}
              {...register('companyName')}
            />
          ) : null}
          <FormAttribute
            id={'address'}
            label="Address"
            isRequired
            isDisabled={!isManualAddress}
            disabledTooltip={
              'You must find the address via the postcode dropdown'
            }
            isInvalid={false}
            placeholder={'Address'}
            error={errors.addressLine1}
            mb={4}
            data-cy={`${cypressTestId}-address`}
            inputCypressId={`${cypressTestId}-address-input`}
            {...register('addressLine1')}
          />
          <FormAttribute
            id={'postcode'}
            label="Postcode"
            isRequired
            isDisabled={!isManualAddress}
            disabledTooltip={
              'You must find the address via the postcode dropdown'
            }
            isInvalid={false}
            placeholder={'Postcode'}
            error={errors.postCode}
            mb={4}
            data-cy={`${cypressTestId}-postcode`}
            inputCypressId={`${cypressTestId}-postcode-input`}
            {...register('postCode')}
          />
        </Box>
      ) : null}
    </>
  );
};
