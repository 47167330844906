import {
  Button,
  Text,
  FormControl,
  FormLabel,
  Input,
  Flex,
  Switch,
  Spinner,
  Center,
  Divider,
  RadioGroup,
  Radio,
  HStack,
  FormErrorMessage,
  Box,
  Stack,
  Alert,
  AlertIcon,
  Icon,
  ModalFooter,
  ModalBody,
} from '@chakra-ui/react';
import {
  DEFAULT_REGION_ID,
  DeliveryOptionId,
  DeliveryVehicleId,
  MAX_DISTANCE_FROM_CENTRE_OF_LONDON_METRES,
} from '@tradeaze-packages/schemas';
import { useEffect, useMemo, useRef, useState } from 'react';
import {
  useCalculateTotalFees,
  useDeliveryOptions,
  useGetDeliveryOptions,
  useGetDeliveryPrice,
} from '@tradeaze/frontend/hooks';
import {
  formatDateIgnoreTime,
  getApiErrorMessage,
  getIsEveryStopOverThreshold,
} from '@tradeaze/shared/utils';
import { DeliveryVehicleIcon } from './../delivery-vehicle/DeliveryVehicleIcon';
import { SmallDatePicker } from '../../shared/SmallDatePicker';
import { captureEvent, initialStartDate } from '@tradeaze/frontend/utils';
import { PostHog } from 'posthog-js/react';
import { optionDescriptions } from './../constants/optionDescriptions';
import { IoIosAddCircle, IoIosRemoveCircle } from 'react-icons/io';
import { WarningTwoIcon } from '@chakra-ui/icons';
import { DeliveryVehicleInfo } from './../delivery-vehicle/DeliveryVehicleInfo';
import { TradeazeLogoNoText } from '../../brand';
import { useNavigate } from 'react-router-dom';
import { getIsValidPostcode, postcodeValidate, handleChangeHeavySideItems, getStopTitle, handleRemoveCollection, handleAddCollection, handleChangeDeliveryVehicle, handlePostcodeChange } from './pricingFunctions';


const vehicleSortOrder: DeliveryVehicleId[] = [
  'BIKE',
  'CAR',
  'VAN_LARGE',
  'VAN_XLARGE',
  'LUTON',
  'FLATBED',
];

export const PricingCalculator: React.FunctionComponent<{
  defaultFromPostcode?: string;
  onClose: () => void;
  merchantId?: string;
  isAdmin?: boolean;
  posthog?: PostHog;
  hidePriorityDelivery: boolean;
  allowLongDistance: boolean;
  isAB?: boolean;
}> = ({
  defaultFromPostcode,
  merchantId,
  onClose,
  isAdmin = false,
  hidePriorityDelivery,
  posthog,
  allowLongDistance,
  isAB,
}) => {
  const navigate = useNavigate();
  const modalRef = useRef<HTMLDivElement>(null);
  const [shouldUseAccountPostcode, setShouldUseAccountPostcode] = useState(
    !!defaultFromPostcode && getIsValidPostcode(defaultFromPostcode)
  );
  const initialStopPostcodes = [defaultFromPostcode ?? '', ''];
  if (!isAB) initialStopPostcodes.push('');

  const [stopPostcodes, setStopPostcodes] = useState<string[]>(initialStopPostcodes);

  const postcodeErrors = useMemo(
    () =>
      postcodeValidate(stopPostcodes),
    [stopPostcodes]
  );

  const hasInvalidPostcodes = useMemo(
    () => postcodeErrors.some(Boolean),
    [postcodeErrors]
  );

  const [deliveryOptionId, setDeliveryOptionId] =
    useState<DeliveryOptionId>('BIKE_THREE_HOUR');

  const [deliveryVehicleId, setDeliveryVehicleId] =
    useState<DeliveryVehicleId>('BIKE');

  const [date, setDate] = useState(initialStartDate);

  const [heavySideItems, setHeavySideItems] = useState<boolean>();

  const [enableCalculation, setEnableCalculation] = useState(false);

  const deliveryOptionsQuery = useGetDeliveryOptions({
    date,
    regionId: DEFAULT_REGION_ID,
  });

  const deliveryOptions = deliveryOptionsQuery.data?.deliveryOptions ?? [];

  const deliveryVehicles = deliveryOptionsQuery.data?.deliveryVehicles ?? [];

  const selectedVehicle = deliveryVehicles.find(
    (v) => v.deliveryVehicleId === deliveryVehicleId
  );

  const { filteredDeliveryOptions, availableDeliveryOptions } =
    useDeliveryOptions({
      deliveryOptions,
      deliveryVehicle: deliveryVehicleId,
      hidePriorityDelivery,
      isAdmin,
    });

  const shouldDisableCalculate =
    hasInvalidPostcodes || heavySideItems === undefined;

  const shouldCalculateDelivery = useMemo(
    () =>
      Boolean(
        enableCalculation &&
          deliveryOptionId &&
          deliveryVehicleId &&
          !hasInvalidPostcodes
      ),
    [
      enableCalculation,
      deliveryOptionId,
      deliveryVehicleId,
      hasInvalidPostcodes,
    ]
  );

  const { data, isFetching, error, status } = useGetDeliveryPrice(
    {
      deliveryOptionId: deliveryOptionId,
      deliveryStopLocations: stopPostcodes,
      throwOverThreshold: false,
      throwOnBikeOverThreshold: !isAdmin,
      heavySideItems,
      scheduledTime: date.toISOString(),
    },
    {
      enabled: shouldCalculateDelivery,
      onSuccess: (data) => {
        if (!posthog) {
          return;
        }
        captureEvent(posthog)('delivery_price_calculated', {
          deliveryOption: deliveryOptionId,
          deliveryVehicle: deliveryVehicleId,
          deliveryStopPostcodes: stopPostcodes,
          isAdmin,
          merchantId,
          heavySideItems,
          scheduledTime: date.toISOString(),
          deliveryPrice: data.deliveryPrice,
          serviceCharge: data.serviceCharge,
        });
      },
    }
  );
  useEffect(() => {
    if (status !== 'success') return;
    if (modalRef.current) {
      modalRef.current.scrollTop = modalRef.current.scrollHeight;
    }
  }, [status]);
  const totalTradeazeCharges = useCalculateTotalFees({
    deliveryPrice: data?.deliveryPrice,
    serviceCharge: data?.serviceCharge,
  });

  const isEveryStopOverThreshold = useMemo(
    () =>
      data?.thresholdDistances
        ? getIsEveryStopOverThreshold(
            data.thresholdDistances,
            MAX_DISTANCE_FROM_CENTRE_OF_LONDON_METRES
          )
        : false,
    [data?.thresholdDistances]
  );

  const isSaturday = useMemo(() => {
    return date.getDay() === 6;
  }, [date]);

  const isOptionAvailable = useMemo(
    () =>
      availableDeliveryOptions.find(
        (ao) => ao.deliveryOptionId === deliveryOptionId
      ),
    [availableDeliveryOptions, deliveryOptionId]
  );

  const handleSetDate = (date: Date | null) => {
    setDate(date ? new Date(formatDateIgnoreTime(date)) : new Date());
  };

  const handleChangeDeliveryOption = (value: DeliveryOptionId) => {
    setDeliveryOptionId(value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && !shouldDisableCalculate) {
      setEnableCalculation(true);
    }
  };

  const handleResetCalculation = () => {
    setEnableCalculation(false);
  };

  const handleChangeUseAccountPostcode = () => {
    setShouldUseAccountPostcode((prevState) => {
      if (!prevState && defaultFromPostcode) {
        setStopPostcodes((prevPostcodes) => [
          defaultFromPostcode,
          ...prevPostcodes.slice(1),
        ]);
      }
      return !prevState;
    });
    handleResetCalculation();
  };

  const handleCalculate = () => {
    setEnableCalculation(true);
  };

  const handleCreateOrder = () => {
    if (posthog) {
      captureEvent(posthog)('start_create_order_from_pricing_modal', {
        deliveryOption: deliveryOptionId,
        deliveryVehicle: deliveryVehicleId,
        stopPostcodes,
        isAdmin,
        merchantId,
        heavySideItems,
        scheduledTime: date.toISOString(),
        deliveryPrice: data?.deliveryPrice,
        serviceCharge: data?.serviceCharge,
        orderType: isAB ? 'A_TO_B' : 'MULTI_COLLECTION',
      });
    }
    navigate('/create-order');
    onClose();
  };

  return (
    <>
    <ModalBody ref={modalRef} maxHeight={'65vh'} overflowY='auto'>
      {defaultFromPostcode && (
        <FormControl mt={2} mb={6} display="flex" alignItems="center">
          <FormLabel htmlFor="use-account-postcode" mb="0">
            Use default pickup postcode {defaultFromPostcode}
          </FormLabel>
          <Switch
            id="use-account-postcode"
            size={'md'}
            checked={shouldUseAccountPostcode}
            defaultChecked={shouldUseAccountPostcode}
            onChange={handleChangeUseAccountPostcode}
          />
        </FormControl>
      )}
      {stopPostcodes.map((postcode, index) => (
        <Flex mt={2} mb={5} alignItems={'center'} justify="space-between">
          <HStack width={'160px'} justifyContent={'flex-start'}>
            <Text fontSize={16} fontWeight={'bold'}>
              {getStopTitle(stopPostcodes, index)}
            </Text>
            {index > (isAB ? 0 : 1) &&
            index < stopPostcodes.length - 1 &&
            !enableCalculation ? (
              <Button
                size="xs"
                variant="unstyled"
                onClick={() => handleRemoveCollection(stopPostcodes, index, setStopPostcodes)}
              >
                <IoIosRemoveCircle fontSize={18} color={'red'} />
              </Button>
            ) : null}
            {!isAB && index === stopPostcodes.length - 2 && !enableCalculation ? (
              <Button
                size="xs"
                variant="unstyled"
                onClick={() => handleAddCollection(stopPostcodes, setStopPostcodes)}
                color={'black'}
              >
                <IoIosAddCircle fontSize={18} />
              </Button>
            ) : null}
          </HStack>
          {enableCalculation ||
          (index === 0 && shouldUseAccountPostcode) ? (
            <Text px={5} my={'8px'}>
              {postcode}
            </Text>
          ) : (
            <Box>
              <FormControl isInvalid={Boolean(postcodeErrors[index])}>
                <Input
                  w={28}
                  name="toPostcode"
                  onChange={(e) => handlePostcodeChange(e, index, stopPostcodes, setStopPostcodes)}
                  value={postcode}
                  onKeyDown={handleKeyDown}
                />
                <FormErrorMessage fontSize={10} w={28}>
                  {postcodeErrors[index]}
                </FormErrorMessage>
              </FormControl>
            </Box>
          )}
        </Flex>
      ))}
      <Box mt={5} mb={4}>
        <Flex justifyContent={'space-between'} alignItems={'center'}>
          <Box>
            <Text fontSize={16} fontWeight={'bold'}>
              Delivery Date
            </Text>
          </Box>
          <SmallDatePicker
            size="small"
            date={date}
            onChange={handleSetDate}
          />
        </Flex>
      </Box>
      <Divider />
      {deliveryOptionsQuery.status === 'loading' && (
        <Center h={'200px'}>
          <Spinner />
        </Center>
      )}
      {deliveryOptionsQuery.status === 'success' && (
        <Box>
          <RadioGroup
            mt={6}
            mb={3}
            onChange={(val: DeliveryVehicleId) => { handleChangeDeliveryVehicle(val,
              deliveryVehicleId,
              deliveryOptionId,
              availableDeliveryOptions,
              setHeavySideItems,
              setDeliveryVehicleId,
              setDeliveryOptionId) }}
            value={deliveryVehicleId}
          >
            <Flex flexWrap="wrap" alignItems={'center'}>
              {deliveryVehicles
                ?.sort((a, b) => {
                  return (
                    vehicleSortOrder.indexOf(a.deliveryVehicleId) -
                    vehicleSortOrder.indexOf(b.deliveryVehicleId)
                  );
                })
                .map(({ deliveryVehicleId, name }) => (
                  <Radio
                    key={deliveryVehicleId}
                    value={deliveryVehicleId}
                    mr={3}
                    mb={3}
                  >
                    <HStack spacing={1}>
                      <DeliveryVehicleIcon
                        deliveryVehicle={deliveryVehicleId}
                      />
                      <Text>{name}</Text>
                    </HStack>
                  </Radio>
                ))}
            </Flex>
            {selectedVehicle ? (
              <DeliveryVehicleInfo
                fontSize={12}
                selectedVehicle={selectedVehicle}
              />
            ) : null}
          </RadioGroup>
          <Divider />
          <RadioGroup
            mt={6}
            mb={3}
            onChange={handleChangeDeliveryOption}
            value={deliveryOptionId}
          >
            <Flex flexWrap="wrap" alignItems="center">
              {filteredDeliveryOptions.map((o) => (
                <Radio
                  key={o.deliveryOptionId}
                  value={o.deliveryOptionId}
                  mr={3}
                  mb={3}
                >
                  <Text>{o.name}</Text>
                </Radio>
              ))}
            </Flex>
            <Text color={'blackAlpha.500'} fontSize={12}>
              {optionDescriptions[deliveryOptionId]}
            </Text>
            {!isOptionAvailable ? (
              <Text fontSize={12} color={'orange.400'}>
                <Icon as={WarningTwoIcon} mr={1} />
                Unavailable for selected date
              </Text>
            ) : null}
          </RadioGroup>
        </Box>
      )}
      {deliveryOptionsQuery.status === 'error' && (
        <Text color={'red.500'}>
          Something went wrong fetching options
        </Text>
      )}
      <Divider />
      <Stack
        direction={'row'}
        justifyContent="space-between"
        spacing={3}
        py={4}
      >
        <Text>
          <Text>Includes yard items (heavyside)</Text>
          <Text color={'blackAlpha.500'} fontSize={12}>
            (e.g. bricks, sand, timber, plasterboard etc..)
          </Text>
        </Text>
        <RadioGroup
          onChange={(value) => { handleChangeHeavySideItems(value, setHeavySideItems, posthog) }}
          value={
            heavySideItems === undefined
              ? undefined
              : heavySideItems === true
              ? 'yes'
              : 'no'
          }
        >
          <Flex flexWrap="wrap" alignItems={'center'}>
            <Radio value={'yes'} mr={3} mb={3}>
              <Text>Yes</Text>
            </Radio>
            <Radio value={'no'} mr={3} mb={3}>
              <Text>No</Text>
            </Radio>
          </Flex>
        </RadioGroup>
      </Stack>
      {status === 'loading' && enableCalculation && (
        <Center h={'218px'}>
          <Spinner />
        </Center>
      )}
      {status === 'error' && enableCalculation && (
        <Center h={36}>
          <Text color="red.500">
            {getApiErrorMessage(error) ?? 'Something went wrong'}
          </Text>
        </Center>
      )}
      {status === 'success' && enableCalculation && (
        <>
          <Divider />
          {isEveryStopOverThreshold ? (
            allowLongDistance ? (
              <Alert
                status="warning"
                borderRadius={'md'}
                my={4}
                size="sm"
              >
                <AlertIcon />
                <Text>
                  <Text fontWeight={'bold'}>
                    Every stop is over the threshold distance from London.
                  </Text>
                  Please confirm with Tradeaze before booking.
                  <Text as="i">{` The below price may not be accurate.`}</Text>
                </Text>
              </Alert>
            ) : (
              <Alert status="error" borderRadius={'md'} my={4} size="sm">
                <AlertIcon />
                <Text>
                  <Text fontWeight={'bold'}>
                    At least one stop must be in London.
                  </Text>
                  You must contact Tradeaze to book this delivery.
                  <Text as="i">{` The below price may not be accurate.`}</Text>
                </Text>
              </Alert>
            )
          ) : null}
          <Flex my={3} justify={'space-between'}>
            <Text>
              Delivery Price
              <Text fontSize={14} color={'grey'}>
                (exc. VAT)
              </Text>
            </Text>
            <Text>£{data.deliveryPrice.toFixed(2)}</Text>
          </Flex>
          <Flex my={2} justify={'space-between'}>
            <Text>
              Service Charge
              <Text fontSize={14} color={'grey'}>
                (exc. VAT)
              </Text>
            </Text>
            <Text>£{data.serviceCharge.toFixed(2)}</Text>
          </Flex>
          <Flex my={4} justify={'space-between'}>
            <Text fontSize={16} fontWeight={'bold'}>
              Total{' '}
              <Text fontSize={14} color={'grey'}>
                (exc. VAT)
              </Text>
            </Text>
            <Box>
              <Text fontSize={16} fontWeight={'bold'} textAlign="right">
                £{totalTradeazeCharges.toFixed(2)}
              </Text>
              {isSaturday && (
                <Text color={'blackAlpha.500'} fontSize={12} mt={2}>
                  (Saturday charges apply)
                </Text>
              )}
            </Box>
          </Flex>
        </>
      )}
      </ModalBody>
      <ModalFooter
          borderTop={'1px'}
          borderColor="blackAlpha.100"
          justifyContent={'space-between'}
        >
          <TradeazeLogoNoText height={'34'} />
          {enableCalculation ? (
            <HStack>
              <Button
                variant={'outline'}
                isLoading={isFetching}
                onClick={handleResetCalculation}
              >
                Reset
              </Button>
              <Button onClick={handleCreateOrder}>Create Order</Button>
            </HStack>
          ) : (
            <Button
              isLoading={isFetching}
              isDisabled={shouldDisableCalculate}
              variant="solid"
              onClick={handleCalculate}
            >
              Calculate
            </Button>
          )}
        </ModalFooter>
    </>
  );
};
