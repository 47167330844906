import {
  Box,
  SimpleGrid,
} from '@chakra-ui/react';
import { HydratedOrder } from '@tradeaze-packages/schemas';
import React from 'react';
import {
  BookingInfo,
  OrderStatus,
  OrderSummaryHeading,
  RouteSummary,
  InvoicingDetails,
  TradeazeOrderFees,
  DeliveryStopsSummary,
} from './order-summary';

export const OrderSummary: React.FunctionComponent<{
  order: HydratedOrder;
  showMerchantName?: boolean;
  proofOfDeliveryBucketname: string;
  columns?: number[];
  isTravisPerkins?: boolean;
  isAdmin?: boolean;
}> = ({
  order,
  showMerchantName = false,
  proofOfDeliveryBucketname,
  columns,
  isTravisPerkins = false,
  isAdmin = false,
}) => {
  const pickups =
    order.deliveryStops?.filter((stop) => stop.type === 'PICK_UP') || [];
  const dropoffs =
    order.deliveryStops?.filter((stop) => stop.type === 'DROP_OFF') || [];
  const isMultiCollection = order.type === 'MULTI_COLLECTION';
  const dropoffsArr = isMultiCollection ? [dropoffs[0]] : dropoffs;
  return (
    <Box>
      <Box mb={12}>
        <OrderSummaryHeading
          type={order.type}
          pickups={pickups}
          dropoffs={dropoffsArr}
          merchantOrderReference={order.merchantOrderReference}
        />
      </Box>
      <SimpleGrid columns={columns} spacing={12}>
        <OrderStatus
          order={order}
          deliveryStop={dropoffs[0]}
          isAdmin={isAdmin}
        />
        {isAdmin ? (
          <InvoicingDetails
            invoicingName={order.invoicingName}
            merchantName={order.Merchant?.merchantName}
            isTravisPerkins={isTravisPerkins}
          />
        ) : null}

        <BookingInfo
          merchantOrderReference={order.merchantOrderReference}
          bookedBy={order.bookedBy}
          orderType={order.type}
          notes={order.notes}
          isTravisPerkins={isTravisPerkins}
        />

        <RouteSummary
          deliveryStop={dropoffs[0]}
          deliveryLength={pickups?.length}
          deliveryDuration={order.deliveryDuration}
          deliveryDistance={order.deliveryDistance}
        />
        <DeliveryStopsSummary
          deliveryStops={pickups}
          proofOfDeliveryBucketname={proofOfDeliveryBucketname}
          isAdmin={isAdmin}
          order={order}
        />
        <DeliveryStopsSummary
          deliveryStops={dropoffs}
          order={order}
          isAdmin={isAdmin}
          proofOfDeliveryBucketname={proofOfDeliveryBucketname}
        />
        <TradeazeOrderFees order={order} />
      </SimpleGrid>
    </Box>
  );
};
