import { DEFAULT_REGION_ID, DeliveryVehicleId, GigJobCurrency, HydratedDelivery } from "@tradeaze-packages/schemas";
import { useCreateGigJob, useGetDeliveryOptions } from "@tradeaze/frontend/hooks";
import { handleError, initialStartDate } from '@tradeaze/frontend/utils';
import { useState } from "react";
import { useAssignDeliveryContext } from "../AssignDeliveryContext";


type JobBoardHookProps = {
  paymentAmount: number;
  paymentCurrency: GigJobCurrency;
  deliveryToAssign: HydratedDelivery;
};
const date = initialStartDate();
export const useSendToJobBoard = ({paymentAmount, paymentCurrency, deliveryToAssign}: JobBoardHookProps) => {
  const assignDeliveryContext = useAssignDeliveryContext();
  const deliveryOptionsQuery = useGetDeliveryOptions({
    date,
    regionId: DEFAULT_REGION_ID,
  });
  const deliveryVehicles = deliveryOptionsQuery.data?.deliveryVehicles ?? [];
  const [showVehicleSelection, setShowVehicleSelection] = useState(false);
  const [successModal, setShowSuccessModal] = useState(false);
  const [selectedVehicles, setSelectedVehicles] = useState<DeliveryVehicleId[] | []>([]);
  const onVehicleSelection = (deliveryVehicleId: DeliveryVehicleId) => {
    setSelectedVehicles((prevSelectedVehicles: DeliveryVehicleId[]) => {
      if (prevSelectedVehicles.includes(deliveryVehicleId)) {
        return prevSelectedVehicles.filter(id => id !== deliveryVehicleId);
      } else {
        return [...prevSelectedVehicles, deliveryVehicleId];
      }
    });
  };
  const createGigJob = useCreateGigJob({
    onSuccess: (_) => {
      setShowVehicleSelection(false);
      setShowSuccessModal(true);
    },
    onError: (error) => {
      handleError(error, {
        showNotification: true,
        notificationMessage: 'Error creating gig job',
        sendToSentry: true,
      });
    },
  });
  const onSendToJobBoard = async () => {
    await createGigJob.mutateAsync({
      body: {
        deliveryIds: [deliveryToAssign.deliveryId],
        paymentAmount,
        paymentCurrency,
        deliveryVehicleIds: selectedVehicles,
      }
    });
  };
  const handleGoToHomepage = () => {
    assignDeliveryContext.handleCancelAssign();
  }

  const isLoading = createGigJob.isLoading;

  return {
    deliveryVehicles,
    showVehicleSelection,
    selectedVehicles,
    successModal,
    setShowVehicleSelection,
    setSelectedVehicles,
    onVehicleSelection,
    onSendToJobBoard,
    setShowSuccessModal,
    handleGoToHomepage,
    isLoading,
  };
};
