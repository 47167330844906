import { Box, Divider, Text, VStack, Tag, HStack } from '@chakra-ui/react';
import { HydratedDelivery } from '@tradeaze-packages/schemas';
import { CardEtaTag, CardStatusDescription } from '../components';
import {
  CardAdminMenu,
  CardDeliveryOption,
  CardDeliveryWindow,
  CardDriverInfo,
  CardItemsInfo,
  CardPostcodes,
  CardProgressBar,
  CardTimeRemaining,
  GridCardContainer,
  GridCardContent,
  GridCardOrderReference,
  GridCardTab,
} from './components';
import { useDeliveryCardData } from './hooks';

export const GridDeliveryCard: React.FC<{
  delivery: HydratedDelivery;
  onSelectDelivery?: (delivery?: HydratedDelivery) => void;
  onHoverDelivery?: (deliveryId: string) => void;
  onHoverLeaveDelivery?: () => void;
  onPressGoTo: (deliveryId: string) => void;
  isAdmin: boolean;
  isSelected: boolean;
  isHovered: boolean;
}> = ({
  delivery,
  isAdmin,
  isSelected,
  isHovered,
  onHoverLeaveDelivery,
  onHoverDelivery,
  onSelectDelivery,
  onPressGoTo,
}) => {
  const {
    deliveryId,
    cancelledAt,
    companyName,
    createdAt,
    deliveredAt,
    deliveryOptionId,
    pickup,
    dropOff,
    deliveryItems,
    deliveryVehicleId,
    deliveryWindowEnd,
    deliveryWindowStart,
    descriptionColorScheme,
    earliestArrival,
    hasNotes,
    isToday,
    isHeavyside,
    orderType,
    latestArrival,
    merchant,
    merchantOrderReference,
    nextStopType,
    deliveryStatus,
    progressColorScheme,
    rider,
    showEta,
    showItemSizing,
    showLate,
    showMerchantName,
    notesPreview,
    showNotesPreview,
    showProgress,
    showRiderVehicle,
    statusDescription,
    updatedAt,
    handleCardClick,
    onCloseMenu,
    ref,
  } = useDeliveryCardData(delivery, isAdmin, onSelectDelivery);
  return (
    <Box
      ref={ref}
      cursor="pointer"
      onClick={handleCardClick}
      onMouseEnter={() => onHoverDelivery?.(deliveryId)}
      onMouseLeave={() => onHoverLeaveDelivery?.()}
    >
      <GridCardContainer>
        {isSelected && isAdmin ? (
          <CardAdminMenu
            deliveryStatus={deliveryStatus}
            assignedRiderId={rider?.riderId}
            onPressGoTo={onPressGoTo}
            onCloseMenu={onCloseMenu}
            deliveryDate={new Date(deliveryWindowEnd)}
            delivery={delivery}
          />
        ) : null}
        <GridCardTab
          createdAt={createdAt}
          deliveryStatus={deliveryStatus}
          vehicleId={deliveryVehicleId}
        />
        <GridCardContent>
          <CardDeliveryOption deliveryOptionId={deliveryOptionId} />
          <CardPostcodes
            deliveryStops={[pickup, dropOff]}
            deliveryStatus={deliveryStatus}
          />
          {companyName && !isAdmin ? (
            <Text fontSize={'0.9em'} color={'blackAlpha.700'}>
              For {companyName}
            </Text>
          ) : null}
          <Divider my={2} />
          {showItemSizing && deliveryItems?.length ? (
            <>
              <CardItemsInfo deliveryItems={deliveryItems} gap={4} />
              <Divider my={2} />
            </>
          ) : null}
          <VStack spacing={2} alignItems={'start'}>
            <CardDeliveryWindow
              deliveryWindowStart={deliveryWindowStart}
              deliveryWindowEnd={deliveryWindowEnd}
            />
            {showProgress ? (
              <CardProgressBar
                colorScheme={progressColorScheme}
                deliveryWindowStart={deliveryWindowStart}
                deliveryWindowEnd={deliveryWindowEnd}
              />
            ) : null}
            {showEta && earliestArrival && latestArrival && nextStopType ? (
              <CardEtaTag
                stopType={nextStopType}
                colorScheme={progressColorScheme}
                earliestArrival={earliestArrival}
                latestArrival={latestArrival}
              />
            ) : (
              <CardTimeRemaining
                deliveryWindowEnd={deliveryWindowEnd}
                showLate={showLate}
                deliveryStatus={deliveryStatus}
                updatedAt={updatedAt}
                deliveredAt={deliveredAt}
                isToday={isToday}
                cancelledAt={cancelledAt}
              />
            )}
          </VStack>
          <Divider my={3} />
          <HStack>
            <CardDriverInfo
              driver={
                rider
                  ? {
                      firstName: rider.firstName,
                      lastName: rider.lastName,
                      vehicleId: rider.deliveryVehicle,
                    }
                  : null
              }
              showVehicle={showRiderVehicle}
              isGigJob={Boolean(delivery.gigJobId)}
              isGigJobBoard={Boolean(delivery.gigJob?.gigJobBoard)}
              showGigJobTag={isAdmin}
            />
          </HStack>
          <CardStatusDescription
            colorScheme={descriptionColorScheme}
            statusDescription={statusDescription}
          />
          <Divider my={3} />
          <GridCardOrderReference
            orderReference={merchantOrderReference || ''}
            merchantName={merchant?.merchantName || ''}
            orderType={orderType}
            hasNotes={hasNotes}
            showMerchantName={showMerchantName}
            isHeavyside={isHeavyside}
            notesPreview={notesPreview}
            showNotesPreview={showNotesPreview}
          />
        </GridCardContent>
      </GridCardContainer>
    </Box>
  );
};
