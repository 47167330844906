import { Box, HStack, Icon, Tag, Text } from '@chakra-ui/react';
import { DeliveryVehicleId } from '@tradeaze-packages/schemas';
import { FaUserCircle } from 'react-icons/fa';
import { RiderAvatar } from '../../../../rider';
import { DotIcon } from '../../../../shared';
import { DeliveryVehicleIcon } from '../../../delivery-vehicle';
import { CheckCircleIcon } from '@chakra-ui/icons';

export const CardDriverInfo: React.FC<{
  driver: {
    firstName: string;
    lastName?: string | null;
    vehicleId?: DeliveryVehicleId | null;
  } | null;
  showVehicle?: boolean;
  isGigJob?: boolean;
  showGigJobTag: boolean;
  isGigJobBoard: boolean;
  isListView?: boolean;
}> = ({ driver, showVehicle, isGigJob, showGigJobTag, isGigJobBoard, isListView }) => {
  const direction = isListView ? 'column': 'row';
  const align = isListView ? 'flex-start': 'center';
  const gigJobTag = (
    <HStack alignItems={align} flexDirection={direction}>
      {showGigJobTag && isGigJob && (
        <Tag
          colorScheme="yellow"
          size="sm"
          fontWeight={'bold'}
          mx={1}
          border="1px solid #FBE7AA"
        >
          GIG
        </Tag>
      )}
      {showGigJobTag && isGigJobBoard && !driver && (
        <Tag
          colorScheme="gray"
          size="md"
          fontWeight={'bold'}
          mx={1}
          border="1px solid #D5D5D5"
        >
          <CheckCircleIcon w={3} h={3} color="#579F6E" mr={1} />
          Job Board
        </Tag>
      )}
    </HStack>
  );
  if (!driver) {
    return (
      <HStack flexDirection={direction} alignItems={align}>
        <HStack>
          <Icon as={FaUserCircle} fontSize={24} color={'blackAlpha.500'}></Icon>
          <Text>Unassigned</Text>
        </HStack>
        {gigJobTag}
      </HStack>
    );
  }

  return (
    <HStack flexDirection={direction} color={'blackAlpha.800'}>
      <HStack>
        <RiderAvatar
          size={'xs'}
          firstName={driver.firstName}
          lastName={driver.lastName}
        />
        <Text>{driver.firstName}</Text>
        {showVehicle && driver.vehicleId ? (
          <>
            <DotIcon />
            <DeliveryVehicleIcon
              fontSize={'1em'}
              deliveryVehicle={driver.vehicleId}
            />
          </>
        ) : null}
        </HStack>
        {gigJobTag}
    </HStack>
  );
};
