import { GrLocation } from 'react-icons/gr';
import { Box, Divider, Flex, Switch, Text } from '@chakra-ui/react';
import React from 'react';
import {
  CreateDeliveryStop,
  MerchantAccount,
} from '@tradeaze-packages/schemas';
import {
  DriverInstructionSection,
  ManualAddressSection,
  PodSection,
  PostCodeSection,
  SaveAddressButton,
  SavedAddressSection,
} from '../common-sections';
import { Attribute, OrDivider, TextValue } from '../../../shared';
import { usePickupSection } from '../../hooks';
import { UseFormReturn } from 'react-hook-form';
import { FormAttribute } from '../../../form';
import { AddMarkerFn, RemoveMarkerFn } from '../../../map';
import { OrderSection } from '../../order-section';

type PickupSectionProps = {
  sectionName: string;
  defaultPickupAddress?: string;
  defaultPickupPostcode?: string;
  defaultPickupPosition?: { latitude: number; longitude: number } | null;
  merchantAccount?: MerchantAccount;
  showExpanded?: boolean;
  stopSequence?: number;
  form: UseFormReturn<CreateDeliveryStop>;
  isSectionComplete: boolean;
  addMarker: AddMarkerFn;
  removeMarker: RemoveMarkerFn;
  isSecondPickup?: boolean;
  restrictedEditing?: boolean;
  cypressTestId: string;
  stopId: number;
};

export const PickupSection = ({
  sectionName,
  defaultPickupAddress,
  defaultPickupPostcode,
  defaultPickupPosition,
  merchantAccount,
  showExpanded,
  stopSequence,
  form,
  isSectionComplete,
  addMarker,
  removeMarker,
  isSecondPickup,
  restrictedEditing,
  cypressTestId,
  stopId,
}: PickupSectionProps) => {
  const {
    shouldUseDefaultPickup,
    instructionsVisibility,
    setInstructionsVisibility,
    handleToggleDefaultPickup,
    savedAddresses,
    savedAddressPlaceholder,
    handleSelectExistingAddress,
    handleSelectLocation,
    handleManualAddress,
    showFullAddress,
    isManualAddress,
    isLoadingSavedAddresses,
    canSaveAddress,
    handleSaveAddress,
    isLoadingCreateAddress,
    canUpdateAddress,
    handleUpdateAddress,
    isLoadingUpdateAddress,
  } = usePickupSection({
    merchantAccount,
    showExpanded,
    defaultPickupPosition,
    defaultPickupAddress,
    defaultPickupPostcode,
    stopSequence,
    form,
    addMarker,
    removeMarker,
    isSecondPickup,
    stopId,
  });

  return (
    <OrderSection
      name={sectionName}
      icon={<GrLocation />}
      isComplete={isSectionComplete}
    >
      {restrictedEditing ? (
        <Box>
          {form.getValues('companyName') ? (
            <Attribute name={'Company Name'}>
              <TextValue text={form.getValues('companyName')} />
            </Attribute>
          ) : null}
          <Attribute name={'Address'}>
            <TextValue text={form.getValues('addressLine1')} />
          </Attribute>
          <Attribute name={'Postcode'}>
            <TextValue text={form.getValues('postCode')} />
          </Attribute>
        </Box>
      ) : null}

      {!restrictedEditing && defaultPickupAddress && defaultPickupPostcode ? (
        <Flex align={'center'} gap={4}>
          <Switch
            ml={4}
            defaultChecked={shouldUseDefaultPickup}
            onChange={handleToggleDefaultPickup}
            colorScheme="yellow"
            data-cy={'default-pickup'}
          />
          <Text>
            Use default pick up address - {defaultPickupAddress},{' '}
            {defaultPickupPostcode}
          </Text>
        </Flex>
      ) : null}
      <Box my={4}>
        {!restrictedEditing && !shouldUseDefaultPickup ? (
          <>
            {merchantAccount ? (
              <>
                <Box my={6}>
                  <SavedAddressSection
                    savedAddressPlaceholder={savedAddressPlaceholder}
                    savedAddresses={savedAddresses}
                    handleSelectExistingAddress={handleSelectExistingAddress}
                    isLoadingSavedAddresses={isLoadingSavedAddresses}
                  />
                </Box>
                <OrDivider my={6} />
              </>
            ) : null}
            <Box my={6}>
              <PostCodeSection
                handleSelectLocation={handleSelectLocation}
                form={form}
                cypressTestId={cypressTestId}
                manualAddressFieldsVisible={showExpanded || showFullAddress}
              />
              <ManualAddressSection
                handleManualAddress={handleManualAddress}
                showFullAddress={showExpanded || showFullAddress}
                isManualAddress={isManualAddress}
                form={form}
                cypressTestId={cypressTestId}
              />
            </Box>
            <SaveAddressButton
              canSaveAddress={canSaveAddress}
              isLoadingCreateAddress={isLoadingCreateAddress}
              handleSaveAddress={handleSaveAddress}
              canUpdateAddress={canUpdateAddress}
              handleUpdateAddress={handleUpdateAddress}
              isLoadingUpdateAddress={isLoadingUpdateAddress}
            />
          </>
        ) : null}
        <Box my={6}>
          <DriverInstructionSection
            instructionsVisibility={instructionsVisibility}
            setInstructionsVisibility={setInstructionsVisibility}
            form={form}
            cypressTestId={cypressTestId}
          />
        </Box>
        <Box my={6}>
          <Divider my={6} />
          <FormAttribute
            id={'collectionReference'}
            label="Pickup Reference"
            isRequired
            isInvalid={Boolean(form.formState.errors.collectionReference)}
            placeholder={'e.g. John Smith / Ticket #123456'}
            mt={'6'}
            error={form.formState.errors.collectionReference}
            description={'Someone to find or a code to reference on arrival'}
            data-cy={'pickup-reference'}
            inputCypressId={'pickup-reference-input'}
            {...form.register('collectionReference')}
          />
          <Divider my={6} />
        </Box>
        <PodSection form={form} />
      </Box>
    </OrderSection>
  );
};
