import { useQuery } from '@tanstack/react-query';
import { getOrderById } from '@tradeaze-packages/api-client';
import { GetOrderByIdQuery } from '@tradeaze-packages/schemas';

export const getOrderQueryKey = (orderId?: string) => {
  const key = ['getOrder'];
  if (orderId) {
    key.push(orderId);
  }
  return key;
};

export const useGetOrder = (
  orderId: string | undefined,
  query?: GetOrderByIdQuery,
) => {
  return useQuery(
    getOrderQueryKey(orderId),
    async () => {
      if (!orderId) {
        return;
      }
      return getOrderById(orderId, query ?? { includeProofMedia: false });
    },
    {
      enabled: !!orderId,
    },
  );
};
