import { Avatar, HStack, SimpleGrid } from '@chakra-ui/react';
import {
  HydratedDeliveryStop,
  HydratedOrder,
} from '@tradeaze-packages/schemas';
import React from 'react';
import { CgTimelapse } from 'react-icons/cg';
import {
  Attribute,
  DateTimeValue,
  OrderStatusValue,
  TextValue,
} from '../../shared';
import { OrderSection } from '../order-section';
import { sectionColumns } from './common';
import { TrackingLink } from './TrackingLink';

export const OrderStatus: React.FunctionComponent<{
  order: HydratedOrder;
  deliveryStop: HydratedDeliveryStop;
  isAdmin?: boolean;
}> = ({ order, deliveryStop, isAdmin }) => {
  const { delivery } = deliveryStop || {};
  return (
    <OrderSection name="Status" icon={<CgTimelapse />}>
      <SimpleGrid columns={sectionColumns} spacing={4}>
        <Attribute name="Order Status">
          <OrderStatusValue orderStatus={order.orderStatus} />
        </Attribute>
        <Attribute name="Created">
          <DateTimeValue dateTime={order.createdAt} />
        </Attribute>
        <Attribute name="Updated">
          <DateTimeValue dateTime={order.updatedAt} />
        </Attribute>
        <Attribute name="Confirmed">
          <DateTimeValue dateTime={order.confirmedAt} />
        </Attribute>
        <Attribute name="Collected">
          <DateTimeValue dateTime={order.deliveredAt} />
        </Attribute>
        <Attribute name="Delivered">
          <DateTimeValue dateTime={order.deliveredAt} />
        </Attribute>
        {order.cancelledAt ? (
          <Attribute name="Cancelled">
            <DateTimeValue dateTime={order.cancelledAt} />
          </Attribute>
        ) : null}
        {order.cancellationReason ? (
          <Attribute name="Cancellation Reason">
            <TextValue text={order.cancellationReason} />
          </Attribute>
        ) : null}
        {delivery?.rider ? (
          <Attribute name="Courier">
            <HStack>
              <Avatar
                size={'xs'}
                name={`${delivery.rider.firstName} ${delivery.rider.lastName}`}
              />
              <TextValue text={delivery.rider.firstName} />
            </HStack>
          </Attribute>
        ) : null}
      </SimpleGrid>
      <TrackingLink deliveryStop={deliveryStop} isAdmin={isAdmin} />
    </OrderSection>
  );
};
