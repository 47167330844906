import { HStack, SimpleGrid } from '@chakra-ui/react';
import { HydratedDeliveryStop } from '@tradeaze-packages/schemas';
import {
  formatMetresToKm,
  formatTimeRange,
  secondsToDurationText,
} from '@tradeaze/shared/utils';
import React from 'react';
import { GrLocation } from 'react-icons/gr';
import { DeliveryVehicleIcon } from '../../delivery';
import { Attribute, DeliveryOptionValue, TextValue } from '../../shared';
import { OrderSection } from '../order-section';
import { sectionColumns } from './common';

export const RouteSummary: React.FunctionComponent<{
  deliveryStop: HydratedDeliveryStop;
  deliveryLength: number;
  deliveryDuration?: number | null;
  deliveryDistance?: number | null;
}> = ({ deliveryStop, deliveryLength, deliveryDuration, deliveryDistance }) => {
  const deliveryVehicle = deliveryStop.delivery?.deliveryVehicleId;
  const deliveryOption = deliveryStop.delivery?.deliveryOptionId;
  const deliveryWindow =
    deliveryStop.windowStart && deliveryStop.windowEnd
      ? formatTimeRange({
          from: deliveryStop.windowStart,
          to: deliveryStop.windowEnd,
        })
      : null;
  return (
    <OrderSection name="Route Details" icon={<GrLocation />}>
      <SimpleGrid columns={sectionColumns} spacing={4}>
        {deliveryVehicle ? (
          <Attribute name="Vehicle Type">
            <HStack>
              <DeliveryVehicleIcon
                fontSize={18}
                deliveryVehicle={deliveryVehicle}
              />
              <DeliveryOptionValue deliveryOption={deliveryOption} />
            </HStack>
          </Attribute>
        ) : null}
        {deliveryLength > 1 ? (
          <Attribute name="Total Pickups">{deliveryLength}</Attribute>
        ) : null}
        {deliveryWindow ? (
          <Attribute name="Delivery Window">
            <TextValue text={deliveryWindow} />
          </Attribute>
        ) : null}
        <Attribute name="Travel Duration">
          <TextValue text={`${secondsToDurationText(deliveryDuration || 0)}`} />
        </Attribute>
        <Attribute name="Travel Distance">
          <TextValue text={`${formatMetresToKm(deliveryDistance || 0)}km`} />
        </Attribute>
      </SimpleGrid>
    </OrderSection>
  );
};
