import { ClientDeliveryOption, DeliveryOptionId, DeliveryVehicleId } from '@tradeaze-packages/schemas';
import { captureEvent } from '@tradeaze/frontend/utils';
import {
  validatePostcode,
} from '@tradeaze/shared/utils';
import { PostHog } from 'posthog-js';
import { getNextOptionOnVehicleChange } from '../../order';

export const postcodeValidate = (stopPostcodes: string[]): (string | null)[] => {
  return stopPostcodes.map((postcode) => {
    if (!validatePostcode(postcode)) {
      return 'Invalid UK postcode';
    }
    if (!postcode.includes(' ')) {
      return 'Postcode must include a space';
    }
    return null;
  });
};

export  const getIsValidPostcode = (postcode: string): boolean => {
  return validatePostcode(postcode) && postcode.includes(' ');
};

export const handleChangeHeavySideItems = (value: string, setState: (val: boolean) => void, posthog?: PostHog) => {
  const valueAsBoolean = value === 'yes';
  setState(valueAsBoolean);
  if (posthog) {
    captureEvent(posthog)(
      `toggle_heavy_side_items_${valueAsBoolean ? 'on' : 'off'}`
    );
  }
};

export const getStopTitle = (stopPostcodes: string[], index: number) => {
  if (index === 0 && stopPostcodes.length === 2) {
    return 'Collection';
  }
  if (index === stopPostcodes.length - 1) {
    return 'Delivery';
  }
  return `Collection ${index + 1}`;
};

export const handleRemoveCollection = (stopPostcodes: string[], index: number, setState: (val: string[]) => void) => {
  const newStopPostcodes = stopPostcodes.filter((_, i) => i !== index);

  setState(newStopPostcodes);
};

export const handleAddCollection = (stopPostcodes: string[], setState: (val: string[]) => void) => {
  const lastStop = stopPostcodes[stopPostcodes.length - 1];

  const newStopPostcodes = [...stopPostcodes.slice(0, -1), '', lastStop];

  setState(newStopPostcodes);
};

export const handleChangeDeliveryVehicle = (
  value: DeliveryVehicleId,
  currentVehicle: DeliveryVehicleId,
  currentOption: DeliveryOptionId | null,
  availableDeliveryOptions: ClientDeliveryOption[],
  setHeavySideItems: (val: boolean) => void,
  setDeliveryVehicleId: (val: DeliveryVehicleId) => void,
  setDeliveryOptionId: (val: DeliveryOptionId) => void
) => {

  const nextOption = getNextOptionOnVehicleChange({
    currentOption,
    currentVehicle,
    newVehicle: value,
    availableDeliveryOptions: availableDeliveryOptions,
  });

  if (value === 'BIKE') {
    setHeavySideItems(false);
  }

  setDeliveryVehicleId(value);
  setDeliveryOptionId(nextOption);
};

export const handlePostcodeChange = (
  e: React.ChangeEvent<HTMLInputElement>,
  stopIndex: number,
  stopPostcodes: string[],
  setStopPostcodes: (val: string[]) => void,
) => {
  const newStopPostcodes = [...stopPostcodes];

  newStopPostcodes[stopIndex] = e.target.value.toUpperCase();

  setStopPostcodes(newStopPostcodes);
};
