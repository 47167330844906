import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Flex,
  IconButton,
  Stack,
  Box,
  Divider,
  Text,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { CheckIcon, CloseIcon, EditIcon } from '@chakra-ui/icons';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import isPostalCode from 'validator/es/lib/isPostalCode';
import {
  Position,
  CreateMerchantProfile,
  validateUkPhoneNumber,
  CreateMerchantProfileSchema,
} from '@tradeaze-packages/schemas';
import { FindAddressesInput } from '../location/FindAddressesInput';
import { validatePostcode } from '@tradeaze/shared/utils';
import { AddMarkerFn, RemoveMarkerFn } from '../map';
import parsePhoneNumberFromString from 'libphonenumber-js';
import { v4 as uuid } from 'uuid';
import { zodResolver } from '@hookform/resolvers/zod';
import { ContactEmailsFormSection } from './ContactEmailsFormSection';
import { useGetManualAddressPosition } from '@tradeaze/frontend/hooks';
import { AutocompleteAddress } from '@tradeaze/shared/services';

export const buildDefaultMerchantAccountDetails = (
  props: Partial<CreateMerchantProfile> &
    Pick<CreateMerchantProfile, 'merchantId' | 'username'>
): CreateMerchantProfile => ({
  merchantName: '',
  address: '',
  city: '',
  postCode: '',
  referencePrefix: '',
  contactEmails: [
    {
      email: '',
      isPrimary: true,
      enableNotifications: true,
      contactEmailId: uuid(),
    },
  ],
  contactNumber: '',
  ...props,
  merchantId: props.merchantId,
  username: props.username,
});

export const AccountDetailsForm: React.FC<{
  handleSaveChanges: (data: CreateMerchantProfile) => void;
  isLoading: boolean;
  isCreatingNew: boolean;
  isEditing?: boolean;
  initialAccountDetails: CreateMerchantProfile;
  handleCancelChanges?: () => void;
  handleStartEditing?: () => void;
  handleAddMerchantMarker?: AddMarkerFn;
  removeMerchantMarker: RemoveMarkerFn;
}> = ({
  handleCancelChanges,
  handleSaveChanges,
  handleStartEditing,
  isCreatingNew,
  isEditing = true,
  isLoading,
  initialAccountDetails,
  handleAddMerchantMarker,
  removeMerchantMarker,
}) => {
  const formMethods = useForm<CreateMerchantProfile>({
    defaultValues: initialAccountDetails,
    mode: 'all',
    resolver: zodResolver(CreateMerchantProfileSchema),
  });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    clearErrors,
    formState: { errors },
  } = formMethods;

  const position = watch('position');
  const postCode = watch('postCode');

  const [isManuallyEditingAddress, setIsManuallyEditingAddress] =
    useState(false);

  const handleSetPosition = useCallback(
    (position: Position | undefined) => {
      setValue('position', position);
    },
    [setValue]
  );

  const isValidPostcode = useMemo(() => validatePostcode(postCode), [postCode]);

  useGetManualAddressPosition({
    handleSetPosition,
    isManualAddress: isManuallyEditingAddress,
    isValidPostcode,
    postCode: postCode,
  });

  const handleManualPickupAddress = () => {
    setIsManuallyEditingAddress(true);
    setValue('position', null);
  };

  useEffect(() => {
    setIsManuallyEditingAddress(false);
  }, [isEditing]);

  useEffect(() => {
    setIsManuallyEditingAddress(false);
  }, [isEditing]);

  useEffect(() => {
    if (position) {
      handleAddMerchantMarker?.({ id: 'merchant', position });
    } else {
      removeMerchantMarker('merchant');
    }
  }, [handleAddMerchantMarker, position, removeMerchantMarker]);

  const handleSelectMerchantLocation = (addressResult: AutocompleteAddress) => {
    const position = {
      longitude: addressResult.longitude,
      latitude: addressResult.latitude,
    };
    setValue('position', position);

    const postcode = addressResult.postcode;
    const address = [addressResult.line_1, addressResult.line_2]
      .filter((el) => !!el)
      .join(', ');
    const city = addressResult.town_or_city;

    if (postcode) {
      setValue('postCode', postcode);
      clearErrors('postCode');
    } else {
      setValue('postCode', '');
    }
    if (address) {
      setValue('address', address);
      clearErrors('address');
    } else {
      setValue('address', '');
    }
    if (city) {
      setValue('city', city);
      clearErrors('city');
    } else {
      setValue('city', '');
    }
  };

  const onChangePhoneNumber = (value: string) => {
    const parsedPhoneNumber = parsePhoneNumberFromString(value, 'GB');

    if (!parsedPhoneNumber?.isValid()) {
      return;
    }

    const contactNumber = parsedPhoneNumber.formatInternational();
    setValue('contactNumber', contactNumber);
    clearErrors('contactNumber');
  };

  return (
    <form onSubmit={handleSubmit((data) => handleSaveChanges(data))}>
      <Flex justify="end">
        {!isCreatingNew &&
          handleCancelChanges &&
          handleStartEditing &&
          (isEditing ? (
            <Stack direction={'row'}>
              <IconButton
                isLoading={isLoading}
                aria-label="Confirm Changes"
                colorScheme={'green'}
                icon={<CheckIcon />}
                type="submit"
              />
              <IconButton
                isLoading={isLoading}
                aria-label="Cancel Changes"
                colorScheme={'red'}
                icon={<CloseIcon />}
                onClick={() => {
                  reset(initialAccountDetails);
                  handleCancelChanges();
                }}
              />
            </Stack>
          ) : (
            <IconButton
              aria-label="Edit details"
              icon={<EditIcon />}
              onClick={() => handleStartEditing()}
            />
          ))}
      </Flex>
      <FormControl
        isRequired={true}
        isInvalid={Boolean(errors.merchantName)}
        my={3}
      >
        <FormLabel htmlFor="companyName">Company Name</FormLabel>
        <Input
          id="companyName"
          disabled={!isEditing}
          placeholder="Company Name"
          {...register('merchantName', {
            required: 'This is required',
          })}
        />
        <FormErrorMessage>{errors.merchantName?.message}</FormErrorMessage>
      </FormControl>
      <Divider my={5} />
      {isEditing ? (
        <Box marginY={6}>
          <FindAddressesInput
            onAddressSelected={handleSelectMerchantLocation}
          />
          <Text
            fontSize={12}
            decoration={'underline'}
            cursor={'pointer'}
            my={2}
            onClick={handleManualPickupAddress}
            color={'blackAlpha.500'}
          >
            Can't find address? Click here to enter manually
          </Text>
        </Box>
      ) : null}
      <FormControl
        isRequired={true}
        isDisabled={!isManuallyEditingAddress || !isEditing}
        isInvalid={Boolean(errors.address)}
        my={3}
      >
        <FormLabel>Address</FormLabel>
        <Input
          id="address"
          type="text"
          placeholder="Address"
          {...register('address', { required: 'This is required' })}
        />
        <FormErrorMessage>{errors.address?.message}</FormErrorMessage>
      </FormControl>
      <FormControl
        isRequired={true}
        isDisabled={!isManuallyEditingAddress || !isEditing}
        isInvalid={Boolean(errors.postCode)}
        my={3}
      >
        <FormLabel>Post Code</FormLabel>
        <Input
          id="postCode"
          type="text"
          placeholder="Post Code"
          {...register('postCode', {
            required: 'This is required',
            onChange(event) {
              setValue('postCode', event.target.value.toLocaleUpperCase());
            },
            validate: (value) =>
              isPostalCode(value, 'GB') || 'Please enter a valid post code',
          })}
        />
        <FormErrorMessage>{errors.postCode?.message}</FormErrorMessage>
      </FormControl>
      <FormControl
        isRequired={true}
        isDisabled={!isManuallyEditingAddress || !isEditing}
        isInvalid={Boolean(errors.city)}
        my={3}
      >
        <FormLabel>City</FormLabel>
        <Input
          id="city"
          type="text"
          placeholder="City"
          {...register('city', { required: true })}
        />
        <FormErrorMessage>{errors.city?.message}</FormErrorMessage>
      </FormControl>

      <Divider my={5} />

      <FormControl
        label="Contact Emails"
        isInvalid={Boolean(errors.contactEmails)}
        my={3}
      >
        <FormLabel>Contact Emails</FormLabel>
        <ContactEmailsFormSection
          formMethods={formMethods}
          isEditing={isEditing}
        />
      </FormControl>
      <FormControl
        isRequired={true}
        isInvalid={Boolean(errors.contactNumber)}
        my={3}
      >
        <FormLabel>Contact Number</FormLabel>
        <Input
          id="contactNumber"
          disabled={!isEditing}
          placeholder="Contact Number"
          type={'tel'}
          {...register('contactNumber', {
            required: 'This is required',
            validate: (value) => validateUkPhoneNumber(value),
            onChange: (e) => onChangePhoneNumber(e.target.value),
          })}
        />
        <FormErrorMessage>{errors.contactNumber?.message}</FormErrorMessage>
      </FormControl>

      <Divider my={5} />

      <FormControl isInvalid={Boolean(errors.referencePrefix)} my={3}>
        <FormLabel>
          Purchase Order Reference Prefix{' '}
          <Text color={'blackAlpha.500'} display={'inline'}>
            (Optional)
          </Text>
        </FormLabel>
        <Input
          id="referencePrefix"
          disabled={!isEditing}
          placeholder="e.g. ABC-"
          {...register('referencePrefix')}
        />
        <Text mx={1} my={1} fontSize={12} color={'blackAlpha.500'}>
          Use this if your purchase order references always starts the same characters
        </Text>
        <FormErrorMessage>{errors.referencePrefix?.message}</FormErrorMessage>
      </FormControl>
      {isCreatingNew && (
        <Button isLoading={isLoading} type="submit" mt={10} size="lg" w="100%">
          Create Account
        </Button>
      )}
    </form>
  );
};
