import {
  CreateOrder,
  MerchantAccount,
  ExistingOrder,
} from '@tradeaze-packages/schemas';
import { AtoBOrderForm } from './order-type-forms';
import { AddMarkerFn, RemoveMarkerFn } from '../map';
import { MultiCollectionOrderForm } from './order-type-forms/multi-collection/MultiCollectionOrderForm';

type EditOrderProps = {
  order: ExistingOrder;
  isAdmin: boolean;
  merchantAccount?: MerchantAccount;
  addPickupMarker: AddMarkerFn;
  addDeliveryMarker: AddMarkerFn;
  removePickupMarker: RemoveMarkerFn;
  removeDeliveryMarker: RemoveMarkerFn;
  onSubmit: (order: CreateOrder) => void;
  isLoading: boolean;
};

export const EditOrder = ({
  order,
  isAdmin,
  merchantAccount,
  addPickupMarker,
  addDeliveryMarker,
  removePickupMarker,
  removeDeliveryMarker,
  onSubmit,
  isLoading,
}: EditOrderProps) => {
  if (order.type === 'A_TO_B') {
    return (
      <AtoBOrderForm
        merchantAccount={merchantAccount}
        isAdmin={isAdmin}
        addPickupMarker={addPickupMarker}
        addDeliveryMarker={addDeliveryMarker}
        removePickupMarker={removePickupMarker}
        removeDeliveryMarker={removeDeliveryMarker}
        onSubmit={onSubmit}
        isLoading={isLoading}
        existingOrder={order}
      />
    );
  }

  if (order.type === 'MULTI_COLLECTION') {
    return (
      <MultiCollectionOrderForm
        isAdmin={isAdmin}
        merchantAccount={merchantAccount}
        addPickupMarker={addPickupMarker}
        addDeliveryMarker={addDeliveryMarker}
        removePickupMarker={removePickupMarker}
        removeDeliveryMarker={removeDeliveryMarker}
        onSubmit={onSubmit}
        isLoading={isLoading}
        existingOrder={order}
      />
    );
  }

  return <div>EditOrder</div>;
};
