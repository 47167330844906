import {
  GetAdminOrdersQuery,
  GetAdminOrdersResponse,
  CancelOrderResponse,
  OrderQueryParams,
  DownloadOrdersCsvResponse,
  DownloadMerchantOrdersCsvResponse,
  GetOrderByIdResponse,
  GetOrderByIdQuery,
} from '@tradeaze-packages/schemas';
import * as client from './client';
import { handleResponse } from './handle-response';

export const getOrderById = async (
  orderId: string,
  query: GetOrderByIdQuery,
): Promise<GetOrderByIdResponse> =>
  handleResponse(
    client.getOrderById({
      path: { orderId },
      query,
    }),
  );

export const getAdminOrdersAmplify = async (
  query: GetAdminOrdersQuery,
): Promise<GetAdminOrdersResponse> =>
  handleResponse(client.getAdminOrders({ query }));

export const cancelOrderAmplify = async ({
  orderId,
  cancellationReason,
}: {
  orderId: string;
  cancellationReason: string;
}): Promise<CancelOrderResponse> =>
  handleResponse(
    client.cancelOrder({
      path: { orderId },
      body: { cancellationReason },
    }),
  );

export const fetchAdminOrdersCsvAmplify = async (
  filters: OrderQueryParams,
): Promise<DownloadOrdersCsvResponse> =>
  handleResponse(client.downloadOrdersCsv({ query: filters }));

export const fetchMerchantOrdersCsvAmplify = async (
  merchantId: string,
  filters: OrderQueryParams,
): Promise<DownloadMerchantOrdersCsvResponse> =>
  handleResponse(
    client.downloadMerchantOrdersCsv({
      query: { ...filters, merchantId },
    }),
  );
