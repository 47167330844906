import { z } from 'zod';
import { InvoiceSchema } from './invoice-schema';
import { MerchantProfileSchema } from './merchant-schema';
import { OrderSchema } from './order-schema';
import { RiderIdentitySchema, RiderAccountSchema } from './rider-schema';
import { CommonDeliveryStopSchema } from './delivery-stop-schema';
import { FleetVehicleSchema } from './fleet-vehicle-schema';
import { ClockInRecordSchema } from './clock-in-schema';
import { DeliveryItemSchema } from './delivery-item-schema';
import { EtaStatusSchema } from './eta-status-schema';
import { DeliverySchema, DeliveryStatusSchema } from './delivery-schema';
import {
  DropoffDeliveryStopSchema,
  PickupDeliveryStopSchema,
} from './delivery-stop-schema';
import { GigJobSchema } from './gig-job-schema';
/**
 * Store hydrated schemas in this file to avoid circular dependencies
 */

export const HydratedDeliverySchema = DeliverySchema.extend({
  rider: RiderIdentitySchema.omit({
    riderLocation: true,
  }).nullish(),
  pickup: PickupDeliveryStopSchema,
  dropOff: DropoffDeliveryStopSchema,
  deliveryItems: z.array(DeliveryItemSchema),
  order: OrderSchema,
  merchant: MerchantProfileSchema.pick({
    merchantId: true,
    merchantName: true,
  }).nullish(),
  etaStatus: EtaStatusSchema.nullish(),
  gigJob: GigJobSchema.nullish(),
});

const hydratedStopExtensions = {
  deliveryStatus: DeliveryStatusSchema.optional(),
  merchantName: z.string().nullish(),
  merchantOrderReference: z.string().nullish(),
  heavySideItems: z.boolean().nullish(),
  bookedBy: z.string().nullish(),
  delivery: HydratedDeliverySchema.omit({
    pickup: true,
    dropOff: true,
    order: true,
  }).nullish(),
  deliveryItems: z.array(DeliveryItemSchema).nullish(),
  linkedDeliveryStops: z.array(CommonDeliveryStopSchema).nullish(),
  proofSignatures: z.array(z.object({
    key: z.string(),
    url: z.string(),
  })).nullish(),
  proofImages: z.array(z.object({
    key: z.string(),
    url: z.string(),
  })).nullish(),
};

export const HydratedDeliveryStopSchema = CommonDeliveryStopSchema.extend(
  hydratedStopExtensions,
);

export const HydratedOrderSchema = OrderSchema.extend({
  Merchant: MerchantProfileSchema.pick({
    merchantName: true,
  }).nullish(),
  deliveries: z.array(HydratedDeliverySchema.omit({ order: true })),
  deliveryStops: z.array(HydratedDeliveryStopSchema).optional(),
});

export const TrackingDeliverySchema = HydratedDeliverySchema.extend({
  etaStatus: EtaStatusSchema.nullish(),
});

export const HydratedInvoiceSchema = InvoiceSchema.extend({
  Merchant: MerchantProfileSchema.pick({
    merchantName: true,
  }).nullish(),
  Order: OrderSchema.nullish(),
});

export const HydratedClockInSchema = ClockInRecordSchema.omit({
  workType: true, // omitted this field as it is also present in RiderIdentitySchema
}).extend({
  rider: RiderIdentitySchema.nullable(),
  fleetVehicle: FleetVehicleSchema.nullable(),
});

export const HydratedGigJobSchema = GigJobSchema.extend({
  rider: RiderIdentitySchema.nullish(),
  deliveries: z.array(
    DeliverySchema.extend({
      pickup: PickupDeliveryStopSchema,
      dropOff: DropoffDeliveryStopSchema,
      deliveryItems: z.array(DeliveryItemSchema),
    }),
  ),
});

export const HydratedFleetVehicleSchema = FleetVehicleSchema.extend({
  owner: RiderIdentitySchema.nullish(),
});

export const HydratedRiderSchema = RiderAccountSchema.extend({
  fleetVehicles: z.array(FleetVehicleSchema).nullish(),
});

export type HydratedOrder = z.infer<typeof HydratedOrderSchema>;
export type HydratedDeliveryStop = z.infer<typeof HydratedDeliveryStopSchema>;
export type HydratedInvoice = z.infer<typeof HydratedInvoiceSchema>;
export type HydratedClockIn = z.infer<typeof HydratedClockInSchema>;
export type TrackingDelivery = z.infer<typeof TrackingDeliverySchema>;
export type HydratedDelivery = z.infer<typeof HydratedDeliverySchema>;
export type PaginatedOrderResponse = {
  orders: HydratedOrder[];
  nextPageIndex: number | null;
};
export type PaginatedDeliveryResponse = {
  deliveries: HydratedDelivery[];
  nextPageIndex: number | null;
};
export type HydratedGigJob = z.infer<typeof HydratedGigJobSchema>;
export type HydratedFleetVehicle = z.infer<typeof HydratedFleetVehicleSchema>;
export type HydratedRider = z.infer<typeof HydratedRiderSchema>;

